import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "720p CGI Commands",
  "path": "/720p_Series_CGI_List/Multimedia_Menu/Audio/",
  "dateChanged": "2018-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_720p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "720p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='720p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Serie_CGI_Befehle/Multimedia_Menu/Audio/' locationFR='/fr/720p_Series_CGI_List/Multimedia_Menu/Audio/' crumbLabel="720p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "the-multimedia-menu--audio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-multimedia-menu--audio",
        "aria-label": "the multimedia menu  audio permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Multimedia Menu :: Audio`}</h2>
    <br /><br />
    <hr></hr>
    <h3 {...{
      "id": "paramcgicmdsetaudioinvolume",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetaudioinvolume",
        "aria-label": "paramcgicmdsetaudioinvolume permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setaudioinvolume`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Set the Volume the Audio Input`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`volume`}</code>{`: `}{`[1-100]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudioinvolume`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudioinvolume&cmd=getaudiooutvolume&cmd=getmdalarm&-aname=sound`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudioinvolume&-volume=70`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudioinvolume&-volume=70&cmd=setaudiooutvolume&-volume=70&cmd=setmdalarm&-aname=sound&-switch=off`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdsetaudiooutvolume",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetaudiooutvolume",
        "aria-label": "paramcgicmdsetaudiooutvolume permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setaudiooutvolume`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Set the Volume the Audio Output`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`volume`}</code>{`: `}{`[1-100]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudiooutvolume`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudioinvolume&cmd=getaudiooutvolume&cmd=getmdalarm&-aname=sound`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudiooutvolume&-volume=70`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudioinvolume&-volume=70&cmd=setaudiooutvolume&-volume=70&cmd=setmdalarm&-aname=sound&-switch=off`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdsetmdalarm-anamesound",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetmdalarm-anamesound",
        "aria-label": "paramcgicmdsetmdalarm anamesound permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setmdalarm&-aname=sound`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`De/Activate Audioalarm ("Noise Detection")`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`switch`}</code>{`: `}{`[on, off]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success, Error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use Param.cgi?cmd=setmdalarm&-aname=relay&-switch=`}{`[on,off]`}{` to De/Activate the Alarm-Out Relay.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getmdalarm&-aname=sound`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudioinvolume&cmd=getaudiooutvolume&cmd=getmdalarm&-aname=sound`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setmdalarm&-aname=sound&-switch=on`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudioinvolume&-volume=70&cmd=setaudiooutvolume&-volume=70&cmd=setmdalarm&-aname=sound&-switch=on`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdsetaencattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetaencattr",
        "aria-label": "paramcgicmdsetaencattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setaencattr`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Set the Audio Encoder for each Channel`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`chn`}</code>{`: `}{`[11, 12, 13]`}{` (first, second or third video channel)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`aeformat`}</code>{`: `}{`[g711a, g726]`}{` (Be aware: the g726 audio format is not widely supported. It is recommend to leave everything at g711a.)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success, Error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaencattr&-chn=11`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getaudioinvolume&cmd=getaudiooutvolume&cmd=getmdalarm&-aname=sound&cmd=getaencattr&-chn=11&cmd=getaencattr&-chn=12&cmd=getaencattr&-chn=13`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaencattr&-chn=11&-aeformat=g711a`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setaudioinvolume&-volume=70&cmd=setaudiooutvolume&-volume=70&cmd=setmdalarm&-aname=sound&-switch=on&cmd=setaencattr&cmd=setaencattr&-chn=11&-aeformat=g711a&cmd=setaencattr&-chn=12&-aeformat=g711a&-chn=13&-aeformat=g726`}</code></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      